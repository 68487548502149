import moment from 'moment/moment';

/* eslint-disable no-useless-escape */
export const OTP_MIN = 0;
export const OTP_SEC = 30;
export const DEFAULT_COUNTRY_CODE = 'IN';

export function ValidateEmail(inputText) {
  var mailformat = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  if (inputText.match(mailformat)) {
    return true;
  } else {
    return false;
  }
}

export const ModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export function formatedDate(strDate) {
  try {
    if (strDate !== null) {
      const date = moment(strDate).format('DD-MM-YYYY');
      return date;
    } else {
      return '';
    }
  } catch (err) {
    return '';
  }
}

export const LANG = 'en';
export const MAPBOX_ACC = process.env.REACT_APP_MAPBOX_ACC;
export const LIMIT = '10';
export const COUNTRY = 'IN';
export const Educations = 'Educations';
export const Languages = 'Languages';

export const REGEX_PAN = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
export const REGEX_GST =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
export const REGEX_CIN =
  /^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;
export const REGEX_PORTFOLIO_LINK =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

export const GET_ALL_VENDORS = 2;
export const FREELANCER = 'Freelancer';
export const BUSINESS = 'Business';

export const FREELANCER_ID = 3;
export const BUSINESS_ID = 2;
export const B2C_ID = 1;

export const sortArr = [
  { label: 'Price - Low to High', value: 'price-low-to-high' },
  { label: 'Price - High to Low', value: 'price-high-to-low' },
  { label: 'Rating - High to Low', value: 'rating-High-to-low' },
];
export const ratingArr = [
  { label: '1.0 and above', value: 1 },
  { label: '2.0 and above', value: 2 },
  { label: '3.0 and above', value: 3 },
  { label: '4.0 and above', value: 4 },
];

export const ELYNKER_COMPLETION = 'elynker completion';
export const FREELANCER_CATEGORY = 'freelancer category';
export const BUSINESS_CATEGORY = 'business category';
export const BANNER1 = 'banner1';
export const ELYNKER_SERVICES = 'elynker services';
export const ELYNKER_TOOLS = 'elynker tools';
export const CREDIT_SERVICES = 'credit services';
export const BANNER2 = 'bannner2';
export const CARDS = 'cards';
export const TILES = 'tiles';
export const JOBLIST = 'job list';
export const POSTJOB = 'Post Job';

export const CARD_BANNER_SECTION = 1;
export const ELYNKER_COMPLETION_SECTION = 2;
export const FREELANCER_CATEGORY_SECTION = 3;
export const BUSINESS_CATEGORY_SECTION = 4;
export const ELYNKER_SERVICES_SECTION = 5;
export const ELYNKER_TOOLS_SECTION = 6;
export const TILES_BANNER_SECTION = 7;

export const homePageWidgetsIdx = [
  CARD_BANNER_SECTION,
  TILES_BANNER_SECTION,
  ELYNKER_COMPLETION_SECTION,
  FREELANCER_CATEGORY_SECTION,
  BUSINESS_CATEGORY_SECTION,
  ELYNKER_SERVICES_SECTION,
  ELYNKER_TOOLS_SECTION,
  TILES_BANNER_SECTION,
  CARDS,
  TILES,
];

//for template
export const PRIV_POL_SLUG = 'privacy-policy';
export const T_AND_C_SLUG = 'terms-and-conditions';
export const SHIP_DEL_SLUG = 'shipping-delivery';
export const CAN_REF_SLUG = 'cancellation-and-refund';
export const CON_US_SLUG = 'contact-us';
export const T_AND_C_SUB_SLUG = 'terms-and-conditions-apply';

export const TERMS = 'TERMS';
export const PRIVACY_POLICY = 'Privacy Policy';
export const SHIPPING_DELIVERY = 'Shipping Delivery';
export const CANCELLATION_REFUND = 'Cancellation and Refund';
export const CONTACT_US = 'Contact Us';
export const SUBSCRIPTION_TERMS = 'Subscription Terms';

export const HOME_PAGE_FL_COUNT = 7;
export const JOB_DESCRIPTION_COUNT = 500;
export const REQUIREMENT_GET_PARAMS = 'search/registrationId/';

export const HOME_SEARCH = 'HOME_SEARCH';
export const BUSINESS_SEARCH = 'BUSINESS_SEARCH';
export const FL_SEARCH = 'FL_SEARCH';
export const CATEGORY_SEARCH = 'CATEGORY_SEARCH';
export const DEFAULT_NAME_FOR_PG = 'Elynker User';
// export const DEFAULT_EMAIL_FOR_PG = 'payu.noreply@elynker.com';
export const DEFAULT_EMAIL_FOR_PG = 'razorpay.transactions@elynker.com';

export const DEFAULT_REQ_CALLBACK_TIME_HRS = 24;
export const DATE_MIN_YEAR = 100;
export const DATE_MAX_YEAR = 18;

export const POSTJOB_IMG = "https://www.elynker.com/static/media/credit_services.webp"
export const POSTJOB_TITLE = "Seeking to recruit a new team member?"
export const POSTJOB_SUBTITLE = "Get resume from various applicants on your WhatsApp"
export const POSTJOB_BUTTON = "Post JOB"
export const POSTJOB_REDIRECT = '/job-posted'
export const SHARE_VENDOR_DETAIL_URL =`${process.env.REACT_APP_WEBSITE_BASE_URL}/b2b-detail/`


// dialog title and message text
export const DIALOG_TITLE = "elynker";
export const DIALOG_NOT_LOGIN_MSG = "You are not logged in. Please log in to use this feature.";
export const DIALOG_LOGOUT_MSG = 'Are you sure you want to logout?';
export const DIALOG_SUB_REQ_SUCC_MSG = 'Thank you for your interest! Our team will be in touch soon to discuss your subscription options.';
export const DIALOG_SUB_REQ_ERR_MSG = 'Your subscription request has not submitted succesfully, Please try again!';
export const DIALOG_REQEST_SUCC_MSG = 'We have received request for call back, we will be revert back to you soon.';
export const DIALOG_REQUIREMENT_SUCC_MSG = 'We have received your requirement, we will be revert back to you soon';
export const DIALOG_VENDOR_DETAIL_SCRN__MSG  = "Vendor does not have detail page.";


//Request callback types
export const CALLBACK_TYPE_GENERIC = 'generic';
export const CALLBACK_TYPE_SERVICE = 'service';
export const CALLBACK_TYPE_SUBSCRIPTION = 'subscription';

// Response status code
export const STATUS_CODE_200 = 200;
export const STATUS_CODE_201 = 201;
export const STATUS_CODE_400 = 400;
export const STATUS_CODE_401 = 401;
export const STATUS_CODE_500 = 500;

//GA Title
export const ACTIVEREQUIREMENTS_TITLE = "Active_Requirements";
export const ADDCATALOG_TITLE = "Add_Catalog";
export const BUSINESS_TITLE = "Business_Category";
export const BUSINESSCATEGORY_TITLE = "Business_Vendors_List";
export const BUSINESSDETAIL_TITLE = "Vendors_Detail";
export const BUSINESSONBOARDING_TITLE = "Business_Onboarding";
export const BUSINESSREQUIREMENT_TITLE = "Business_Requirement";
export const REQUIREMENT_VERIFY_TITLE = "Requirement_Verify";
export const BUSINESS_OUTREACH_TITLE = "Business_Outreach";
export const COMPANY_DETAILS_TITLE = "Company_Details";
export const COMPLETE_PROFILE_TITLE = "begin_signup";
export const CRAETE_JOB_REQUIREMENT_TITLE = "Job_Requirement";
export const ELYNKER_SERVICE_TITLE = "Elynker_services";
export const FREELANCER_CATEGORYS_TITLE = "Freelancer_Category";
export const FREELANCER_CATEGORYS_VENDORS_TITLE = "Freelancer_Vendors";
export const FREELANCER_UPDATE_PROFILE_TITLE = "Freelancer_Update_Profile";
export const FREELANCERONBOARDING_TITLE = "Freelancer_Onboarding";
export const FL_ADDBIO_TITLE = "Freelancer_About_You";
export const FL_PROFILE_DETAILS_TITLE = "Freelancer_Prodile_Details";
export const FL_SETRATE_TITLE = "Freelancer_SetRate";
export const FL_UPLOADRESUME_TITLE = "Freelancer_Upload_Resume";
export const FL_UPLOADSERVICE_TITLE = "Freelancer_Add_Service";
export const FL_ADDSERVICE_TITLE = "Freelancer_Add_Service";
export const FL_CATALOG_TITLE = "Freelancer_Add_Catalog";
export const FL_ADDROLE_TITLE = "Freelancer_Role";
export const FL_UPDATE_PROFILE_TITLE = "Freelancer_Update_Profile";
export const FREELANCER_DETAIL_TITLE = "Freelancer_Detail";
export const VAS_TITLE = "Value Added Service";
export const HOMEPAGE_TITLE = "HomePage";
export const JOBAPPLICATION_TITLE = "Job_Application";
export const JOBLISTING_TITLE = "Job_Listing";
export const JOBAPPLY_TITLE = "Job_Apply";
export const JOBDETAIL_TITLE = "Job_Detail";
export const JOBPOSTED_TITLE = "Posted_Job_Listing";
export const JOBSEARCH_TITLE = "Job_Listing";
export const LOGIN = "LOGIN";
export const MANAGEWEBSITE_TITLE = "Manage_Website";
export const MYCATALOG="My_Catalog";
export const MYSERVICE="My_Service";
export const HOMEPAGE="Home_Page";
export const BUSINESS_OUTRICH_OTP="Business_OutReach_OTP";

export const PROFILE_TITLE = "Profile";


// GAEvent
export const CLICKED = 'clicked';
export const LOAD = 'page_load';
export const PRODUCT_CATALOG_CHUNK_SIZE = 4;
export const PAGE_DATA_LIMIT = 20;
