import axios from 'axios';
import { SAVE_SIGNIN_DATA, LOGOUT_USER } from '../../pages/Otp/redux/types';
import { Educations, Languages } from '../constants';
import { resetUserData } from '../../pages/Otp/redux/actions/submit-code-action';
import { resetB2BStepData } from '../../pages/B2BOnboardingSteps/redux/actions/b2b-onboarding-steps-action';
import { resetComapanyData } from '../../pages/CompanyDetails/redux/actions/company-details-form-action';
import { resetProfileData } from '../../pages/ProfileDetails/redux/actions/profile-details-form-action';
import { resetAddCatalogData } from '../../pages/AddCatalog/redux/actions/add-catalog-action';
import { resetMyCatalogData } from '../../pages/MyCatalog/redux/actions/mycatalog-action';
import { resetMyServiceData } from '../../pages/MyServices/redux/actions/mycatalog-action';
import { resetOfferedServiceData } from '../../pages/OfferedServices/redux/actions/offered-services-form-action';
import { resetUpdateProfileeData } from '../../pages/UpdateProfile/store/actions/profileActions';
import { resetFLAddBioData } from '../../pages/FreeLancer/FL-AddBio/redux/actions/fl-add-bio-action';
import { resetFLProfileData } from '../../pages/FreeLancer/FL-Profile-Details/redux/actions/fl-profile-details-action';
import { resetHourlyRate } from '../../pages/FreeLancer/FL-SetRate/redux/actions/fl-set-rate-action';
import { resetFLResumeData } from '../../pages/FreeLancer/FL-UploadResume/redux/actions/fl-upload-resume-action';
import { resetFLUploadServiceData } from '../../pages/FreeLancer/FL-UploadServiceCatalog/redux/actions/fl-upload-service-action';
import { resetFLUProfRoleData } from '../../pages/FreeLancer/FLAddProfessionalRole/redux/actions/fl-add-profession-action';
import { resetFLAddServiceData } from '../../pages/FreeLancer/FLAddService/redux/actions/add-service-action';
import { resetLoginData } from '../../pages/Login/redux/actions/submit-mobileno-action';
import { resetCompleteProfileData } from '../../pages/CompleteProfile/redux/actions/start-connecting-action';
import { resetFLStepData } from '../../pages/FreeLancer/FLOnboardingSteps/redux/actions/fl-onboarding-steps-action';
import { resetRequirementList } from '../../pages/ActiveRequirements/redux/actions/active-requirement-action';
import {
  clearSubscription,
  resetSubscription,
} from '../../pages/Subscription/redux/action/susbcription-action';
import { history } from '../../store/store';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const SOLR_BASE_URL = process.env.REACT_APP_SOLR_BASE_URL;
export const POSTOFFICE_SOLR_BASE_URL =
  process.env.REACT_APP_POSTOFFICE_SOLR_BASE_URL;
export const UPLOAD_BASE_URL = process.env.REACT_APP_UPLOAD_BASE_URL;
export const SMS_BASE_URL = process.env.REACT_APP_SMS_BASE_URL;
export const COMPANY_LOGO_BASE_URL = `${UPLOAD_BASE_URL}/uploads/company/company_logo/`;
export const MEMBERS_CONTACTED = '/save/membersContacted';
export const COVER_IMAGE_BASE_URL = `${UPLOAD_BASE_URL}/uploads/cover/cover_images/`;
export const PRODUCT_IMAGE_BASE_URL = `${UPLOAD_BASE_URL}/uploads/products/products_images/`;
export const RESUME_BASE_URL = `${UPLOAD_BASE_URL}/uploads/freelancer/resumes/`;
export const BANNER_BASE_URL = `${UPLOAD_BASE_URL}/uploads/banner/banner_images/`;
export const UPLOAD_REVIEW_IMAGE =
  UPLOAD_BASE_URL + '/uploads/reviews/review_images/';
export const SIGNIN = '/auth/signin';
export const SIGNUP = '/auth/signup';
export const GET_SUBSCRIPTION = '/subscription';
export const GET_USER = '/users/';
export const GET_TRENDING_PRODUCT_CATEGORY = '/trending-product-category';
export const GET_TRENDING_JOB_CATEGORY = '/trending-job-category';

export const GET_POST_REVIEW = '/reviews/vendors';
export const BUSINESS_POST = '/business/';
export const GET_UNIT = '/moduleDetails/miscellaneous/searchByName/';
export const ALLSTATE = '/statemaster';
export const ALLCITY = '/citymaster';
export const CATEGORIES = '/categories/search/category_type/';
export const PRODUCT_IMAGE_UPLOAD = '/products/images';
export const SINGLE_PRODUCT_UPLOAD = '/products/product';
export const GET_CATALOG_PRODUCTS = '/users/products/';
export const SINGLE_PRODUCT_UPDATE = '/products/';
export const COMPANY_LOGO_UPLOAD = '/update/companyLogo/';
export const COVER_PHOTO_UPLOAD = '/update/coverImage/';
export const UPDATE_USER = '/registration/';
export const UPDATE_OUTREACH_USER = '/registration-user-email/';
export const MARK_OUTREACH_USER_VARIFIED = '/registration-user-email-status/';
export const SOCIAL_LINKS = '/users/sociallinks/';
export const SOCIAL_LINK_BY_ID = '/sociallinks/';
export const CITY_MASTER = '/citymaster';
export const DELETE_SINGLE_IMAGE = '/products/images/';
export const FRL_RESUME = '/resume/';
export const FRL_BANNER = '/user/banners/';
export const FRL_DELETE_BANNER = '/user/banner/';
export const FRL_CERTIFICATE = '/certificate/';
export const POSTBUISNESSQRENQUIRY = '/qrcontacted';
export const VENDOR_DETAILS = '/vendorDetails';
export const FREELANCER_VENDOR_DETAILS = '/vendorDetails/freelancer';
export const GET_SUBSCRIBED_SUBSCRIPTION = '/subscription-items';
export const PARENT_CATEGORY = '/products/parentCategory/';
export const SOCIAL_MEDIA_MASTER = '/socialmediamaster';
export const SOCIAL_MEDIA_IMAGE_PATH = `${UPLOAD_BASE_URL}/uploads/social/social_images/`;
export const SAVE_BUISNESS_CARD_IMAGE = '/card/imageUpload';
export const ALL_CATEGORIES = '/categories';
export const ALL_VENDORS = '/vendors/';
export const ENQUIRY = '/enquiry';
export const ENQUIRY_VENDOR = '/enquiry/vendor';
export const WIDGET_IMAGE_BASE_URL = `${UPLOAD_BASE_URL}/uploads/widget/widget_images/`;
export const MASTER_EDUCATION = `${BASE_URL}/moduleDetails/miscellaneous/searchByName/${Educations}`;
export const MASTER_LANGUAGE = `${BASE_URL}/moduleDetails/miscellaneous/searchByName/${Languages}`;
export const CATEGORY_LOGO_BASE_URL = `${UPLOAD_BASE_URL}/uploads/category/category_logo/`;
// export const VENDORS_SEARCH = `${SOLR_BASE_URL}/solr/registration/search/`;
// export const VENDORS_SEARCH = `${SOLR_BASE_URL}/solr/jobs/search/`;
export const VENDORS_SEARCH = `${SOLR_BASE_URL}/jobs/search/`;
export const HOMEPAGE_WIDGET = '/widgets/mobile';
export const B2B_SUBMIT_REQUIREMENT = '/customer/requirement/';
export const UPLOADS_ADMIN_TOOLS = '/uploads/admin/tools/';
export const SERVICE_TOOLS = 'admin/tools/search/is_active/true';
export const TEMPLATE_PAGE = '/page';
export const ADD_ROLE = '/users/addRole/';
export const SET_ROLE = '/users/setRole/';
export const ENQUIRY_CHECK = '/enquiry-check';
export const PAYMENT_RESPONSE = '/paymentResponse';
export const SUBSCRIPTION_ORDER = '/orders';
export const SUBSCRIPTION_DETAILS = '/subscribed-details';
export const SEND_OTP = '/send-otp';
export const VERIFY_OTP = '/verify-otp';
export const GENERATE_PAY_HASH = '/generate-payment-hash';

export const JOBS = '/jobs/';

export const JOBLIST = '/jobs/listing/';

export const JOB_LIST_SEARCH = '/jobs/job/';

export const JOB_APPLY = '/job-application';

export const JOB_COUNT = '/jobs/search/registrationId';

export const JOB_APPLICANT = '/job-application/search/jobId';

export const HOMEPAGE_JOB = '/jobs/search/status';

export const RATING_DATA = '/jobs/registration';

export const ENQUIRY_MSG = '/enquire-msg';
export const BULK_UPLOAD = '/bulk-products';

// Razorpay Constants
export const PAYMENT_ORDER = '/razorpay/payment-order';
export const PAYMENT_VERIFY = '/razorpay/payment-verify';
export const ELYNKER_LOGO_URL =
  UPLOAD_BASE_URL + UPLOADS_ADMIN_TOOLS + '/elynker_logo_bg.jpg';
//

// whatsapp url
export const GET_SUBCATEGORY_VENDOR_LIST = '/vendors-listing-v2/';
export const GET_VENDOR_CITIES = '/vendors-cities/';
export const CHECK_USER_STATUS = '/users/status/';
export const WHATSAPP_URL = 'https://wa.me/';
export const POSTOFFICE_CITY = `${POSTOFFICE_SOLR_BASE_URL}/post-office/search-city/`;
export const POSTOFFICE_PINCODE = `${POSTOFFICE_SOLR_BASE_URL}/post-office/search-pincode/`;
export const CHECK_SUB_DOMAIN_AVL = '/seller-website/search/url/';
export const GET_SUBDOMAIN_API =
  '/moduleDetails/miscellaneous/searchByName/Domains';
export const POST_TESTIMONIAL_API = '/seller-testimonial';
export const GET_TESTIMONIAL_API = '/seller-testimonial/search/';
export const POST_MANAGE_DATA = '/seller-website';
export const GET_PRODUCT_BY_REGISTRATION_ID = '/products/product/search/';
export const SEARCH_UUID = '/registration/search/uuid';

export const ALL_VENDORS_BY_UUID = '/vendors-new-v2/';
export const GET_SUBCATEGORY_VENDOR_LIST_BY_UUID = '/vendors-listing-new/';

const axiosInstance = axios.create({
  baseURL: BASE_URL, // our API base URL
});

const axiosSolrInstance = axios.create({
  baseURL: SOLR_BASE_URL, // our API base URL
});

const axioPincodeSolrInstance = axios.create({
  baseURL: POSTOFFICE_SOLR_BASE_URL, // our API base URL
});

const axiosSMSInstance = axios.create({
  baseURL: SMS_BASE_URL, // our API base URL
});

let store;

export const injectStore = (_store) => {
  store = _store;
};

axiosInstance.interceptors.request.use(
  (config) => {
    if (config.params) {
      console.log('REQUEST PARAMS', config.params);
    }
    if (store) {
      const token = store?.getState()?.signin?.userData?.accessToken;
      config.headers['x-access-token'] = token || '';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (
      response.config.url.includes('/auth/signin') &&
      response.status === 200
    ) {
      store?.dispatch({ type: SAVE_SIGNIN_DATA, payload: response.data });
    }
    return response;
  },
  function (error) {
    if (error?.response && error?.response?.status === 401) {
      console.error('Unauthorized access');
      clearAllDataAndNavigateLogin();
    }
    return Promise.reject(error);
  }
);

axiosSMSInstance.interceptors.request.use(
  (config) => {
    if (config.params) {
      console.log('REQUEST PARAMS', config.params);
    }
    if (store) {
      const token = store?.getState()?.signin?.userData?.accessToken;
      config.headers['x-access-token'] = token || '';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosSMSInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (
      response.config.url.includes('/auth/signin') &&
      response.status === 200
    ) {
      store?.dispatch({ type: SAVE_SIGNIN_DATA, payload: response.data });
    }
    return response;
  },
  function (error) {
    if (error?.response && error?.response?.status === 401) {
      console.error('Unauthorized access');
      clearAllDataAndNavigateLogin();
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
export { axiosSolrInstance, axiosSMSInstance, axioPincodeSolrInstance };

function clearAllDataAndNavigateLogin() {
  store?.dispatch({ type: LOGOUT_USER });
  store?.dispatch(resetUserData());
  store?.dispatch(resetB2BStepData());
  store?.dispatch(resetComapanyData());
  store?.dispatch(resetProfileData());
  store?.dispatch(resetAddCatalogData());
  store?.dispatch(resetMyCatalogData());
  store?.dispatch(resetMyServiceData());
  store?.dispatch(resetOfferedServiceData());
  store?.dispatch(resetUpdateProfileeData());
  store?.dispatch(resetFLAddBioData());
  store?.dispatch(resetFLProfileData());
  store?.dispatch(resetHourlyRate());
  store?.dispatch(resetFLResumeData());
  store?.dispatch(resetFLUploadServiceData());
  store?.dispatch(resetFLUProfRoleData());
  store?.dispatch(resetFLAddServiceData());
  store?.dispatch(resetLoginData());
  store?.dispatch(resetCompleteProfileData());
  store?.dispatch(resetFLStepData());
  store?.dispatch(resetRequirementList());
  store?.dispatch(resetSubscription());
  store?.dispatch(clearSubscription());
  localStorage.removeItem('user_loggedIn');
  localStorage.clear();
  history?.replace('/login');
}
